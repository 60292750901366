<template>
  <div>
    <v-footer absolute color="grey darken-3" class="grey--text" height="30px" style="font-size: 15px;">{{ text }}</v-footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      text: '© 2021, h-chiku'
    };
  }
};
</script>